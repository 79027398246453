"use client";

import { datadogRum } from "@datadog/browser-rum";

type DatadogRumProps = {
    appId: string;
    clientToken: string;
    env: string;
};

let rumInitiated = false;

const initRum = (rumProps: DatadogRumProps) => {
    rumInitiated = true;
    datadogRum.init({
        applicationId: rumProps.appId,
        clientToken: rumProps.clientToken,
        site: "datadoghq.com",
        service: "forge",
        env: rumProps.env,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input",
    });
};

export type { DatadogRumProps };
export default function DatadogRum(rumProps: DatadogRumProps) {
    if (!rumInitiated && process.env.NEXT_PUBLIC_ENV !== "local") {
        initRum(rumProps);
    }

    return null;
}
