import(/* webpackMode: "eager", webpackExports: ["AppRouterStyleRegistry"] */ "/builds/irtech/investment-research-distribution/forge-render/apps/node_modules/.pnpm/@gs-ux-uitoolkit-react+next@18.12.1_@emotion+css@11.11.2_next@14.2.26_@babel+core@7.26._5d351cee50bced888c50098ce6789c19/node_modules/@gs-ux-uitoolkit-react/next/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/builds/irtech/investment-research-distribution/forge-render/apps/node_modules/.pnpm/next@14.2.26_@babel+core@7.26.7_@opentelemetry+api@1.8.0_babel-plugin-macros@3.1.0_reac_9276898677755b13d58369a6a88d4f2a/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/builds/irtech/investment-research-distribution/forge-render/apps/render/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/builds/irtech/investment-research-distribution/forge-render/apps/node_modules/.pnpm/next@14.2.26_@babel+core@7.26.7_@opentelemetry+api@1.8.0_babel-plugin-macros@3.1.0_reac_9276898677755b13d58369a6a88d4f2a/node_modules/next/font/local/target.css?{\"path\":\"src/utils/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/goldman-sans/GoldmanSans_W_Lt.woff2\",\"weight\":\"300\"},{\"path\":\"../../public/fonts/goldman-sans/GoldmanSans_W_Rg.woff2\",\"weight\":\"400\"},{\"path\":\"../../public/fonts/goldman-sans/GoldmanSans_W_Md.woff2\",\"weight\":\"500\"},{\"path\":\"../../public/fonts/goldman-sans/GoldmanSans_W_Bd.woff2\",\"weight\":\"700\"}],\"variable\":\"--font-sans\"}],\"variableName\":\"defaultGoldmanSans\"}");
;
import(/* webpackMode: "eager" */ "/builds/irtech/investment-research-distribution/forge-render/apps/node_modules/.pnpm/next@14.2.26_@babel+core@7.26.7_@opentelemetry+api@1.8.0_babel-plugin-macros@3.1.0_reac_9276898677755b13d58369a6a88d4f2a/node_modules/next/font/local/target.css?{\"path\":\"src/utils/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/noto-sans/jp/noto-sans-jp-japanese-400-normal.woff2\",\"weight\":\"400\"},{\"path\":\"../../public/fonts/noto-sans/jp/noto-sans-jp-japanese-400-normal.woff\",\"weight\":\"400\"}],\"weight\":\"400\",\"display\":\"swap\",\"adjustFontFallback\":false,\"variable\":\"--font-sans\"}],\"variableName\":\"jaGoldmanSans\"}");
;
import(/* webpackMode: "eager" */ "/builds/irtech/investment-research-distribution/forge-render/apps/node_modules/.pnpm/next@14.2.26_@babel+core@7.26.7_@opentelemetry+api@1.8.0_babel-plugin-macros@3.1.0_reac_9276898677755b13d58369a6a88d4f2a/node_modules/next/font/local/target.css?{\"path\":\"src/utils/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/noto-sans/sc/noto-sans-sc-chinese-simplified-400-normal.woff2\",\"weight\":\"400\"},{\"path\":\"../../public/fonts/noto-sans/sc/noto-sans-sc-chinese-simplified-400-normal.woff\",\"weight\":\"400\"}],\"weight\":\"400\",\"display\":\"swap\",\"adjustFontFallback\":false,\"variable\":\"--font-sans\"}],\"variableName\":\"zhGoldmanSans\"}");
;
import(/* webpackMode: "eager" */ "/builds/irtech/investment-research-distribution/forge-render/apps/node_modules/.pnpm/next@14.2.26_@babel+core@7.26.7_@opentelemetry+api@1.8.0_babel-plugin-macros@3.1.0_reac_9276898677755b13d58369a6a88d4f2a/node_modules/next/font/local/target.css?{\"path\":\"src/utils/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/goldman-sans/GoldmanSansCd_W_Rg.woff2\",\"weight\":\"300\"},{\"path\":\"../../public/fonts/goldman-sans/GoldmanSansCd_W_Rg.woff2\",\"weight\":\"400\"},{\"path\":\"../../public/fonts/goldman-sans/GoldmanSansCd_W_Bd.woff2\",\"weight\":\"700\"}],\"variable\":\"--font-sansCondensed\"}],\"variableName\":\"defaultGoldmanSansCondensed\"}");
;
import(/* webpackMode: "eager" */ "/builds/irtech/investment-research-distribution/forge-render/apps/node_modules/.pnpm/next@14.2.26_@babel+core@7.26.7_@opentelemetry+api@1.8.0_babel-plugin-macros@3.1.0_reac_9276898677755b13d58369a6a88d4f2a/node_modules/next/font/local/target.css?{\"path\":\"src/utils/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/noto-sans/jp/noto-sans-jp-japanese-400-normal.woff2\",\"weight\":\"400\"},{\"path\":\"../../public/fonts/noto-sans/jp/noto-sans-jp-japanese-400-normal.woff\",\"weight\":\"400\"}],\"weight\":\"400\",\"display\":\"swap\",\"adjustFontFallback\":false,\"variable\":\"--font-sansCondensed\"}],\"variableName\":\"jaGoldmanSansCondensed\"}");
;
import(/* webpackMode: "eager" */ "/builds/irtech/investment-research-distribution/forge-render/apps/node_modules/.pnpm/next@14.2.26_@babel+core@7.26.7_@opentelemetry+api@1.8.0_babel-plugin-macros@3.1.0_reac_9276898677755b13d58369a6a88d4f2a/node_modules/next/font/local/target.css?{\"path\":\"src/utils/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/noto-sans/sc/noto-sans-sc-chinese-simplified-400-normal.woff2\",\"weight\":\"400\"},{\"path\":\"../../public/fonts/noto-sans/sc/noto-sans-sc-chinese-simplified-400-normal.woff\",\"weight\":\"400\"}],\"weight\":\"400\",\"display\":\"swap\",\"adjustFontFallback\":false,\"variable\":\"--font-sansCondensed\"}],\"variableName\":\"zhGoldmanSansCondensed\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/irtech/investment-research-distribution/forge-render/apps/render/src/components/DatadogRum/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReportTemplateContext","ReportTemplateContextProvider"] */ "/builds/irtech/investment-research-distribution/forge-render/apps/ui-components/packages/utils/context-providers/dist/index.es2.js");
;
import(/* webpackMode: "eager", webpackExports: ["ContactProfileContext","ContactProfileContextProvider"] */ "/builds/irtech/investment-research-distribution/forge-render/apps/ui-components/packages/utils/context-providers/dist/index.es3.js");
;
import(/* webpackMode: "eager", webpackExports: ["BookmarkGlossaryContext","BookmarkGlossaryContextProvider"] */ "/builds/irtech/investment-research-distribution/forge-render/apps/ui-components/packages/utils/context-providers/dist/index.es4.js");
;
import(/* webpackMode: "eager", webpackExports: ["ReportBody"] */ "/builds/irtech/investment-research-distribution/forge-render/apps/ui-components/packages/utils/context-providers/dist/index.es5.js");
